import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import ReactPlayer from 'react-player'
import { useMount, useWindowSize } from 'react-use'

import { Layout } from '../components'
import { container, padding, colours, type, bgIcon } from '../styles/global'
import { media, useBreakpoint } from '../styles/utils'
import { parseACF } from '../utils'

const Roster = (props) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const [isHovered, setHovered] = useState(false)
    const [isTablet, setIsTablet] = useState(false)
    const [theme, setTheme] = useState('normal')
    const [playing, setPlaying] = useState(true)
    const { height } = useWindowSize()

    const detectTablet = useBreakpoint('tablet')
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')

    useMount(() => {
        setIsTablet(detectTablet)
    })

    useEffect(() => {
        setIsTablet(detectTablet)
    }, [detectTablet])

    // Handle Hover List Items

    const handleHover = (boolean, index) => {
        if (index) {
            setActiveIndex(index)
        }

        setHovered(boolean)
    }

    // List

    const renderServices = (services) => {
        if (!services.length) return

        const serviceOffering = services.map((item, i) => {
            return (
                <>
                    <Item>{item}</Item>
                </>
            )
        })

        return (
            <Services>
                {serviceOffering}
            </Services>
        )
    }

    const renderList = (props) => {
        if (!data.clients) return

        return data.clients.map((item, i) => {
            return (
                <>
                    <Item
                        as={'a'}
                        target="_blank"
                        href={item.link}
                        key={i}
                        onMouseEnter={() => {
                            handleHover(true, i.toString())
                        }}
                        onMouseLeave={() => {
                            handleHover(false)
                        }}
                    >
                        
                            <Icon
                                animate={
                                    isHovered && activeIndex == i
                                        ? 'hidden'
                                        : 'show'
                                }
                                variants={animatedItem}
                            />

                            <Arrow
                                animate={
                                    isHovered && activeIndex == i
                                        ? 'show'
                                        : 'hidden'
                                }
                                variants={animatedArrow}
                            />
                            <Wrap>
                                <Label>{item.title}</Label>
                                {renderServices(item.services)}
                            </Wrap>
                    </Item>
                </>
            )
        })
    }

    // Mobile List

    const renderMobileList = (props) => {
        if (!data.clients) return

        return data.clients.map((item, i) => {
            return (
                <>
                    <Item as={'a'} target="_blank" href={item.link} key={i}>
                        <Icon />
                        <Label>{item.title}</Label>
                        <Video
                            playing={playing && activeIndex == i}
                            muted
                            loop
                            playsinline
                            url={item.video}
                            width={'100%'}
                            height={'100%'}
                            key={i}
                        />
                    </Item>
                </>
            )
        })
    }

    // Videos

    const renderVideo = (props) => {
        if (!data.clients) return

        return data.clients.map((item, i) => {
            if (!item.video && !item.video_vimeo) return

            return (
                <>
                    <AnimatePresence>
                        {activeIndex == i && (
                            <VideoWrapper
                                key={i}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 1 }}
                            >
                                <Video
                                    key={item.video_vimeo ? item.video_vimeo : item.video}
                                    playing
                                    muted
                                    loop
                                    playsinline
                                    url={item.video_vimeo ? item.video_vimeo : item.video}
                                    width={'100%'}
                                    height={'100%'}
                                />
                            </VideoWrapper>
                        )}
                    </AnimatePresence>
                </>
            )
        })
    }

    return (
        <Layout
            meta={data && data.seo}
            theme={theme}
            hideFooter={true}
            stopResize={true}
        >
            <Container height={height}>
                <Meta>
                    <Heading>Roster</Heading>
                    {data.short_description && (
                        <Text
                            dangerouslySetInnerHTML={{
                                __html: data.short_description,
                            }}
                        />
                    )}
                </Meta>

                <List>
                    {/*{!isTablet ? renderList() : renderMobileList()}*/}
                    {renderList()}
                </List>

                <Videos>{renderVideo()}</Videos>

                {/*{!isTablet && <Videos>{renderVideo()}</Videos>}*/}
            </Container>
        </Layout>
    )
}

// Shared

const Meta = styled.div``
const Item = styled(motion.div)``
const Heading = styled.h2``
const Text = styled.p``
const VideoWrapper = styled(motion.div)``
const Video = styled(ReactPlayer)``
const Icon = styled(motion.div)``
const Arrow = styled(motion.div)``
const Label = styled.div``
const Wrap = styled.div``

// Layout

const Container = styled.div`
	${container}
    ${padding}
	justify-content: space-between;
	padding-top: 120px;
    padding-bottom: 60px;
	background: ${colours.black};
	min-height: 100vh;

	${media.tablet`
		flex-direction: column;
		padding: 0;
		height: 100%;
		min-height: ${(props) => props.height}px;
		justify-content: flex-start;
	`}

    ${Meta} {
		flex-basis: 40%;
        position: relative;
        z-index: 40;

		${media.tablet`
			flex-basis: 100%;
			/*position: absolute;
			top: 0;
			left: 0;*/
			padding: 80px 30px 30px;
		`}

        ${Heading} {
            ${type.heading2}
			color: ${colours.white};
        }

		${Text} {
			font-size: 24px;
			color: ${colours.white};
			max-width: 400px;
		}
    }
`

// List

const Services = styled.div``

const List = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    position: relative;
    z-index: 20;
    max-height: 80%;

    ${media.tablet`
        grid-template-columns: 1fr;
		padding: 0 30px 80px;
        max-height: 100%;
	`}

    ${Item} {
        display: flex;
        gap: 12px;

        ${media.tablet`
			position: relative;
			padding-bottom: 0;
		`}
        
        ${Icon} {
            position: absolute;
            width: 60px;
            height: 60px;
            background-image: url(${require('../assets/icons/red-circle.svg')});
            ${bgIcon}
            z-index: 20;

            ${media.desktop`
                width: 38px;
                height: 38px;
            `}

            ${media.desktopSmall`
                width: 30px;
                min-width: 30px;
                height: 30px;
            `}

            ${media.tablet`
                position: relative;
                width: 36px;
                min-width: 36px;
                height: 30px;
                margin-bottom: 8px;
            `}
        }

        ${Arrow} {
            position: absolute;
            width: 60px;
            height: 60px;
            background-image: url(${require('../assets/icons/arrow.svg')});
            ${bgIcon}
            margin-bottom: 16px;

            ${media.desktop`
                width: 38px;
                height: 38px;
            `}

            ${media.desktopSmall`
                width: 28px;
                height: 28px;
            `}

            ${media.tablet`
                width: 28px;
                height: 28px;
                margin-bottom: 8px;
            `}
        }

        ${Wrap}{
            display: flex;
            flex-direction: column;
            padding-left: 80px;

            ${media.desktop`
                padding-left: 52px;
            `}

            ${media.desktopSmall`
                gap: 6px;
                padding-left: 52px;
            `}

            ${media.tablet`
                padding-bottom: 4px;
            `}

            ${Label} {
                font-size: 70px;
                line-height: 1;
                color: ${colours.white};
                
                margin: 0;
                z-index: 20;

                ${media.desktop`
                    font-size: 48px;
                `}

                // ${media.desktopSmall`
                //     font-size: 48px;
                //     // padding-left: 44px;
                // `}
    
                ${media.tablet`
                    font-size: 32px;
                    padding-left: 12px;
                    padding-bottom: 4px;
                `}
            }   

            ${Services} {
                display: flex;
                flex-wrap: wrap;
                gap: 0 12px;
                opacity: 0;
                // margin-top: -8px;
                padding-left: 4px;

                ${media.tablet`
                    opacity: 1;
                    margin-top: 0;
                    flex-wrap: wrap;
                    padding-left: 12px;
				`}
    
                ${Item}{
                    color: ${colours.red};
                    ${type.bodySmall}
                }
            }
        }

        ${Video} {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            filter: brightness(0.9);

            video {
                object-fit: cover;
                z-index: 10;
            }
        }

        &:hover {
            ${Label} {
                color: ${colours.red};

                ${media.tablet`
					padding-left: 12px;
				`}
            }

            ${Services}{
                opacity: 1;
            }
        }
    }
`

// Video

const Videos = styled.div`
    ${VideoWrapper} {
        ${Video} {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            filter: brightness(0.7);

            video {
                object-fit: cover;
                z-index: 10;
            }
        }
    }
`

// Animations

const animatedContainer = {
    hidden: {
        opacity: 1,
    },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.5,
            delayChildren: 1,
            yoyo: Infinity,
        },
    },
}

const animatedItem = {
    hidden: {
        scaleX: 0,
        scaleY: 0,
        opacity: 0,
    },
    show: {
        scaleX: 1,
        scaleY: 1,
        opacity: 1,
    },
}

const animatedArrow = {
    hidden: {
        scaleX: 0,
        scaleY: 0,
        x: 0,
    },
    show: {
        scaleX: 1,
        scaleY: 1,
        x: 0,
    },
}

// Query

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "roster" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Roster
